import { FacilityTimeService } from '$/app/services';
import { DateTimeFormatName } from '$shared/constants';
import { getFormattedDateTime } from '$shared/formatters/date-time-formatter';
import { ToLuxonParam } from '$shared/utils';
import { Pipe, PipeTransform, inject } from '@angular/core';

type FacilityDateTimePipeOptions = {
  format?: DateTimeFormatName;
  keepLocalTime?: boolean;
};

@Pipe({
  name: 'facilityDateTime',
  standalone: true
})
export class AlcFacilityDateTimePipe implements PipeTransform {
  private readonly ft = inject(FacilityTimeService);

  transform(
    datetime: ToLuxonParam,
    formatOrOptions?: DateTimeFormatName | FacilityDateTimePipeOptions
  ): any {
    if (!datetime) {
      return datetime;
    }

    if (typeof formatOrOptions === 'string') {
      const facilityDatetime = this.ft.convertDateTime(datetime);

      return getFormattedDateTime(facilityDatetime, formatOrOptions);
    } else if (typeof formatOrOptions === 'object') {
      const { format, keepLocalTime } = formatOrOptions;

      const facilityDatetime = keepLocalTime
        ? this.ft.convertDateTimeKeepingLocalTime(datetime)
        : this.ft.convertDateTime(datetime);

      return format
        ? getFormattedDateTime(facilityDatetime, format)
        : facilityDatetime;
    } else {
      const facilityDatetime = this.ft.convertDateTime(datetime);

      return facilityDatetime;
    }
  }
}
