import { FeathersService } from '$/app/services/feathers.service';
import { DocumentsWsActions } from '$/app/store/documents/documents.actions';
import { IDocument, IDocumentCounts } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';
import { from, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DocumentsApiService extends AbstractApiService<IDocument> {
  constructor(feathers: FeathersService, store: Store) {
    super('documents', feathers, store, {
      entityName: 'document',
      created: DocumentsWsActions.documentCreated,
      patched: DocumentsWsActions.documentPatched,
      removed: DocumentsWsActions.documentRemoved
    });
  }
  getDocumentMetrics(params) {
    return from(
      this.service.find(params) as Promise<{
        total: number;
        skip: number;
        limit: number;
        data: IDocumentCounts;
      }>
    ).pipe(map((res) => res.data));
  }
}
