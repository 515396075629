import { createFeature, createReducer, on } from '@ngrx/store';
import { initialState, DietPageQueries, dietsAdapter } from './diets.state';
import {
  DietsApiActions,
  DietsGeneralActions,
  DietsWsActions
} from './diets.actions';
import { getRecords } from '$shared/utils';
import { getPaginationData } from '$/app/utils';

export const dietsFeature = createFeature({
  name: 'diets',
  reducer: createReducer(
    initialState,

    // on((state) => {
    //   return { ...state, loading: true, error: null };
    // }),

    on(DietsApiActions.loadDietsSuccess, (state, action) => {
      const queryType = action.metaData?.pageQueryType;
      const pagination = getPaginationData<DietPageQueries>(
        state,
        action.diets,
        queryType
      );
      const data = getRecords(action.diets);

      return dietsAdapter.setAll(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(DietsApiActions.getDietSuccess, (state, action) => {
      const queryType = action.metaData?.pageQueryType;
      const pagination = getPaginationData<DietPageQueries>(
        state,
        action.diet,
        queryType
      );
      const data = getRecords(action.diet);

      return dietsAdapter.upsertMany(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),
    on(DietsApiActions.createDietSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),
    on(DietsApiActions.updateDietSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),
    on(
      DietsApiActions.loadDietsFail,
      DietsApiActions.getDietFail,
      DietsApiActions.createDietFail,
      DietsApiActions.updateDietFail,
      DietsApiActions.deleteDietFail,
      (state, action) => {
        return { ...state, loading: false, error: action.error };
      }
    ),

    on(
      DietsWsActions.dietsCreated,
      DietsWsActions.dietsPatched,
      (state, action) => {
        return dietsAdapter.upsertOne(action.diet, state);
      }
    ),
    on(DietsWsActions.dietsRemoved, (state, action) => {
      return dietsAdapter.removeOne(action.id, state);
    }),
    on(DietsGeneralActions.addDiets, (state, action) => {
      return dietsAdapter.upsertMany(action.diets, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),
    on(DietsGeneralActions.clearDiets, () => {
      return dietsAdapter.removeAll({ ...initialState });
    })
  )
});
