import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlcCommonModule } from '../../alc-common.module';

@Component({
    selector: 'alc-indicator',
    imports: [AlcCommonModule],
    template: `
    <ion-icon class="mr-1" src="/assets/icon/{{ iconSet }}/{{ icon }}.svg" />
    <span class="indicator-content">
      {{ label }}
    </span>
  `,
    styles: [
        `
      :host {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
      .indicator-icon {
        margin-right: 8px;
        font-size: 1.125em;
      }
    `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlcIndicatorComponent {
  @Input() icon: string;
  @Input() iconSet?: string = 'material';
  @Input() label: any;
}
