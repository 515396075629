import {
  AlcAction,
  Params,
  ResidentMedicalProfessional,
  Update
} from '$/models';
import { IResident } from '$shared/residents/residents.interface';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentFacesheetPageActions = createActionGroup({
  source: 'Resident Facesheet Page',
  events: {
    'Update Avatar': props<Update<IResident>>(),

    'Fetch Resident': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    }),

    'Get Residents': (params: Params) => ({ params }),

    'Load Diagnoses': (params: Params = { query: {} }) => ({ params }),

    'Load Resident Insurances': (params: Params = { query: {} }) => ({
      params
    }),

    'Delete Resident Group Member': props<{ id: string }>(),

    'Delete Resident Diet': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    }),
    'Delete Resident Insurance': (
      id: string,
      params: Params = { query: {} }
    ) => ({
      id,
      params
    }),

    'Delete Resident Allergy': (
      id: string,
      params: Params = { query: {} }
    ) => ({
      id,
      params
    }),

    'Delete Resident Diagnosis': (
      id: string,
      params: Params = { query: {} }
    ) => ({
      id,
      params
    }),

    'Delete Resident Medical History': (
      id: string,
      params: Params = { query: {} }
    ) => ({
      id,
      params
    }),

    'Load Medical Events': (params: Params = { query: {} }) => ({ params }),

    'Remove Resident Medical Professional': AlcAction<{ id: string }>(),

    'Set Primary Medical Professional':
      AlcAction<Update<ResidentMedicalProfessional>>(),

    'Move Resident': (
      residentId: string,
      toFacilityId: string,
      movedAt: string,
      params: Params = { query: {} }
    ): Update<IResident> => ({
      id: residentId,
      changes: {},
      params: {
        ...params,
        query: {
          ...params.query,
          $actions: [
            {
              moveResident: {
                toFacilityId,
                movedAt,
                copyLogsSinceMove: false
              }
            }
          ]
        }
      }
    })
  }
});
