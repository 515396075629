import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const DashboardOverviewPageActions = createActionGroup({
  source: 'Dashboard Overview Page',
  events: {
    'Load Missed Medication Issues': (params: Params = { query: {} }) => ({
      params
    }),
    'Get Appointments': (params: Params = { query: {} }) => ({ params }),
    'Get Medication Tasks': (params: Params = { query: {} }) => ({ params }),
    'Get Care Tasks': (params: Params = { query: {} }) => ({ params }),
    'Get Count Discrepancies': (params: Params = { query: {} }) => ({ params }),
    'Get Incident Reports': (params: Params = { query: {} }) => ({ params }),
    'Get Missed Care Tasks': (params: Params = { query: {} }) => ({ params }),
    'Get Missed Medication Tasks': (params: Params = { query: {} }) => ({
      params
    }),
    'Get Missed Prns': (params: Params = { query: {} }) => ({ params }),
    'Get Missed Bowel Movements': (params: Params = { query: {} }) => ({
      params
    }),
    'Get Expiring Medications': (params: Params = { query: {} }) => ({
      params
    }),
    'Get Expired Medications': (params: Params = { query: {} }) => ({ params }),
    'Get Documents': (params: Params = { query: {} }) => ({ params }),
    'Get Low Medications': (params: Params = { query: {} }) => ({ params })
  }
});
