import { FileViewerService } from '$/app/services/ui/file-viewer.service';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject
} from '@angular/core';

@Component({
    selector: 'alc-release-image',
    imports: [CommonModule],
    template: `
    <figure class="!mx-0 hover:cursor-pointer" (click)="viewImage()">
      <img [src]="src" [alt]="caption" />
      <figcaption class="mt-4 text-center text-sm text-medium-dark">
        {{ caption }}
      </figcaption>
    </figure>
  `,
    styles: [
        `
      :host {
        display: block;
      }
    `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlcReleaseImageComponent {
  protected readonly fileViewer = inject(FileViewerService);

  @Input({ required: true }) src: string;
  @Input() caption: string;

  viewImage() {
    this.fileViewer.openImageWithUrl(this.src);
  }
}
