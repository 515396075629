import {
  AbstractApiService,
  BmLogsApiService,
  CareTasksApiService,
  DocumentsApiService,
  IncidentReportsApiService,
  MedicationInventoryItemsApiService,
  MedicationsApiService,
  MedicationTasksApiService,
  PrnsApiService
} from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MedicationInventoryItemCountsApiService } from '../medication-inventory-item-counts/medication-inventory-item-counts.service';

@Injectable({ providedIn: 'root' })
export class MetricsService extends AbstractApiService {
  constructor(
    feathers: FeathersService,
    store: Store,
    private itemInventoryCountService: MedicationInventoryItemCountsApiService,
    private incidentService: IncidentReportsApiService,
    private careTaskService: CareTasksApiService,
    private medicationTaskService: MedicationTasksApiService,
    private prnsService: PrnsApiService,
    private bmLogService: BmLogsApiService,
    private medInventoryItemService: MedicationInventoryItemsApiService,
    private documentService: DocumentsApiService,
    private medicationService: MedicationsApiService
  ) {
    super('metrics', feathers, store, {
      entityName: 'metric'
    });
  }

  getItemInventoryCount(params) {
    return this.itemInventoryCountService.getAll(params);
  }

  getIncidentCount(params) {
    return this.incidentService.getAll(params);
  }

  getMissedCareTaskCount(params) {
    return this.careTaskService.getAll(params);
  }

  getMedicationTaskCount(params) {
    return this.medicationTaskService.getAll(params);
  }

  getPrnCount(params) {
    return this.prnsService.getAll(params);
  }

  getBmLogCount(params) {
    return this.bmLogService.getAll(params);
  }

  getMedInventoryItem(params) {
    return this.medInventoryItemService.getAll(params);
  }

  getLowMedicationsCount(params) {
    return this.medicationService.getAll(params);
  }

  getDocumentCount(params) {
    return this.documentService.getDocumentMetrics(params);
  }
}
