import { StateId } from '../lookups/states.lookup';

export type IStateConfigLabel = {
  name: string;
  abbreviation: string;
};

export type IStateConfig = {
  mar: {
    label: IStateConfigLabel;
  };
  incidentReporting: {
    primaryReport: {
      label: IStateConfigLabel;
      formNumber: string;
      submission: {
        email: string;
        deadline: {
          days: number;
          dayType: 'business' | 'calendar';
        };
      };
    };
  };
};

export type IStateConfigDictionary = {
  [key in StateId]?: IStateConfig;
};

// TODO: Need to add a level for facility type either under state or under each config section
// or maybe an applicableFor array under each config section that has the facility types that it applies to
export const stateConfigDictionary: IStateConfigDictionary = {
  'US-CA': {
    mar: {
      label: {
        name: 'Medication Administration Record',
        abbreviation: 'MAR'
      }
    },
    incidentReporting: {
      primaryReport: {
        label: {
          name: 'Unusual Incident/Injury Report',
          abbreviation: ''
        },
        formNumber: '624',
        submission: {
          email: '',
          deadline: {
            days: 7,
            dayType: 'calendar'
          }
        }
      }
    }
  },
  'US-TX': {
    mar: {
      label: {
        name: 'Medication Administration Record',
        abbreviation: 'MAR'
      }
    },
    incidentReporting: {
      primaryReport: {
        label: {
          name: 'Provider Investigation Report',
          abbreviation: 'PIR'
        },
        formNumber: '3613a',
        submission: {
          email: 'ciiprovider@hhs.texas.gov',
          deadline: {
            days: 5,
            dayType: 'calendar'
          }
        }
      }
    }
  },
  'US-FL': {
    mar: {
      label: {
        name: 'Medication Observation Record',
        abbreviation: 'MOR'
      }
    },
    incidentReporting: {
      primaryReport: {
        label: {
          name: '',
          abbreviation: ''
        },
        formNumber: '',
        submission: {
          email: '',
          deadline: {
            days: 0,
            dayType: 'calendar'
          }
        }
      }
    }
  }
};
