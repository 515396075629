import { AlcCommonModule } from '$/app/shared/alc-common.module';
import { CommonModule } from '@angular/common';
import { Component, computed, input, numberAttribute } from '@angular/core';
import { range } from 'lodash';

@Component({
    selector: 'alc-skeleton-item',
    imports: [CommonModule, AlcCommonModule],
    templateUrl: 'skeleton-item.component.html'
})
export class AlcSkeletonItemComponent {
  start = input<'avatar' | 'image' | 'icon' | 'none'>('avatar');
  end = input<'checkbox' | 'radio' | 'date' | 'none'>('none');
  lines = input<string>('full');
  rows = input(2, { transform: numberAttribute });
  count = input(1, { transform: numberAttribute });

  items = computed(() => {
    return range(this.count());
  });
}
