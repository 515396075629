import { createActionGroup, props } from '@ngrx/store';
import { Metrics } from './metrics.state';

export const MetricsActions = createActionGroup({
  source: 'Metrics API',
  events: {
    'Get Metrics Success': props<{ metrics: Metrics }>(),
    'Get Metrics Fail': props<{ error: any }>()
  }
});
