import { Pipe, PipeTransform } from '@angular/core';
import { castArray, isNil } from 'lodash';

type ReplacementConfig = {
  replace: string;
  withValue: string;
  all?: boolean;
};

@Pipe({
  name: 'replace',
  standalone: true
})
export class AlcReplacePipe implements PipeTransform {
  transform(
    value: string,
    configs: ReplacementConfig | ReplacementConfig[]
  ): any {
    if (!value) {
      return value;
    }

    const _configs = castArray(configs);

    return _configs.reduce((acc, config) => {
      return isNil(config.all) || config.all
        ? acc.replaceAll(config.replace, config.withValue)
        : acc.replace(config.replace, config.withValue);
    }, value);
  }
}
