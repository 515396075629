export interface State {
  loading: boolean;
  loaded: boolean;
  error: any;
  metrics: Metrics;
}

export interface Metrics {
  countDiscrepancies?: number;
  incidentReports?: number;
  missedCareTasks?: number;
  missedMedicationTasks?: number;
  missedPrns?: number;
  missedBowelMovements?: number;
  expiredMedicationInventoryItems?: number;
  expiringMedicationInventoryItems?: number;
  lowMedication?: number;
  completeDocuments?: number;
  urgentDocuments?: number;
  upcomingDocuments?: number;
  documentsTotal?: number;
}

export const initialState: State = {
  metrics: {
    countDiscrepancies: 0,
    incidentReports: 0,
    missedCareTasks: 0,
    missedMedicationTasks: 0,
    missedPrns: 0,
    missedBowelMovements: 0,
    expiredMedicationInventoryItems: 0,
    expiringMedicationInventoryItems: 0,
    lowMedication: 0,
    completeDocuments: 0,
    urgentDocuments: 0,
    upcomingDocuments: 0
  },
  loading: false,
  loaded: false,
  error: null
};
