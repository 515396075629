import { StateConfigSelectors } from '$/app/store/shared/selectors/state-specific-labels.selectors';
import { IStateConfigLabel } from '$shared/constants/state-configuration';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input
} from '@angular/core';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

@Component({
  selector: 'alc-state-label',
  standalone: true,
  template: ` {{ label() }} `,
  styles: [``],
  host: {
    class: 'inline'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlcStateLabelComponent {
  private readonly store = inject(Store);

  type = input<keyof IStateConfigLabel>('name');
  default = input<string>('');
  path = input.required<string>();

  stateConfig = this.store.selectSignal(StateConfigSelectors.selectStateConfig);

  label = computed(() => {
    return get(
      this.stateConfig(),
      `${this.path()}.label.${this.type()}`,
      this.default()
    );
  });
}
