import { IResidentDiet } from '$shared/services/resident-diet.schema';
import { Paginated } from '$shared/types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ResidentDietsApiActions = createActionGroup({
  source: 'Resident Diet',
  events: {
    'Load Resident Diets Success': props<{
      residentDiets: Paginated<IResidentDiet>;
      metaData: { pageQueryType: string };
    }>(),
    'Get Resident Diet Success': props<{
      residentDiet: IResidentDiet;
      metaData: { pageQueryType: string };
    }>(),
    'Create Resident Diet Success': props<{
      residentDiet: IResidentDiet;
    }>(),
    'Update Resident Diet Success': props<{
      residentDiet: IResidentDiet;
    }>(),
    'Delete Resident Diet Success': props<{ id: string }>(),

    'Load Resident Diets Fail': props<{ error: Error }>(),
    'Get Resident Diet Fail': props<{ error: Error }>(),
    'Create Resident Diet Fail': props<{ error: Error }>(),
    'Update Resident Diet Fail': props<{ error: Error }>(),
    'Delete Resident Diet Fail': props<{ error: Error }>()
  }
});

export const ResidentDietsWsActions = createActionGroup({
  source: 'Resident Diet WS',
  events: {
    'Resident Diets Created': props<{
      residentDiet: IResidentDiet;
    }>(),
    'Resident Diets Patched': props<{
      residentDiet: IResidentDiet;
    }>(),
    'Resident Diets Removed': props<{ id: string }>()
  }
});

export const ResidentDietsGeneralActions = createActionGroup({
  source: 'Resident Diet General',
  events: {
    'Clear Resident Diets': emptyProps(),
    'Add Resident Diets': props<{
      residentDiets: IResidentDiet[];
    }>()
  }
});
