import { FeathersService } from '$/app/services/feathers.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DietsWsActions } from './diets.actions';
import { IDiet } from '$shared/services/diet.schema';
import { AbstractApiService } from '$/app/services';

@Injectable({ providedIn: 'root' })
export class DietsApiService extends AbstractApiService<IDiet> {
  constructor(feathers: FeathersService, store: Store) {
    super('diets', feathers, store, {
      entityName: 'diet',
      created: DietsWsActions.dietsCreated,
      patched: DietsWsActions.dietsPatched,
      removed: DietsWsActions.dietsRemoved
    });
  }
}
