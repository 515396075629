import { Update } from '$/models';
import { IResidentDiet } from '$shared/services/resident-diet.schema';
import { Params } from '@angular/router';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentDietFormModalActions = createActionGroup({
  source: 'Resident Diet Form Page',
  events: {
    'Create Resident Diet': props<{
      residentDiet: IResidentDiet;
      params: Params;
    }>(),
    'Update Resident Diet': props<Update<IResidentDiet>>(),
    'Load Diets': (params: Params = { query: {} }) => ({ params })
  }
});
