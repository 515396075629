import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlcAddressPipe } from './address.pipe';
import { AlcTimeFormatPipe } from './convert24to12hours.pipe';
import { AlcDocumentNamePipe } from './document-name.pipe';
import { AlcDosePlaceholderPipe } from './dose-placeholder.pipe';
import { AlcExtractFileNameFromPathPipe } from './extract-file-name-from-path.pipe';
import { AlcFacilityDateTimePipe } from './facility-date-time.pipe';
import { AlcFeetInchesPipe } from './feet-inches.pipe';
import { AlcFileIdToUrlPipe } from './file-id-to-url.pipe';
import { AlcFileSizePipe } from './file-size.pipe';
import { AlcFormatterPipe } from './formatter.pipe';
import { AlcHasFeatureFlagPipe } from './has-feature-flag.pipe';
import { AlcHasPermissionPipe } from './has-permission.pipe';
import { AlcHtmlPipe } from './html.pipe';
import { AlcLookupPipe } from './lookup.pipe';
import { AlcLookupTablePipe } from './lookupTable.pipe';
import { AlcMedicationIconPipe } from './medication-icon.pipe';
import { AlcMedicationMeasurementPipe } from './medication-measurement.pipe';
import { AlcMedicationNamePipe } from './medication-name.pipe';
import { AlcNoopPipe } from './noop.pipe';
import { AlcNullSymbolPipe } from './null-symbol.pipe';
import { AlcOperatorPipe } from './operator.pipe';
import { AlcOrdinalPipe } from './ordinal.pipe';
import { AlcParenthesizePipe } from './parenthesize.pipe';
import { AlcPersonNamePipe } from './person-name.pipe';
import { AlcPluralizeMedFormPipe } from './pluralize-med-form.pipe';
import { AlcPluralizePipe } from './pluralize.pipe';
import { AlcRelativeTimePipe } from './relative-time.pipe';
import { AlcReplacePipe } from './replace.pipe';
import { AlcRoomPipe } from './room.pipe.pipe';
import { AlcThemePipe } from './theme.pipe';
import { AlcUtcDatePipe } from './utc-date.pipe';
import { AlcYesNoPipe } from './yesNo.pipe';

const pipes = [
  AlcAddressPipe,
  AlcDocumentNamePipe,
  AlcDosePlaceholderPipe,
  AlcExtractFileNameFromPathPipe,
  AlcFacilityDateTimePipe,
  AlcFeetInchesPipe,
  AlcFileIdToUrlPipe,
  AlcFileSizePipe,
  AlcFormatterPipe,
  AlcHasFeatureFlagPipe,
  AlcHasPermissionPipe,
  AlcHtmlPipe,
  AlcLookupPipe,
  AlcLookupTablePipe,
  AlcMedicationIconPipe,
  AlcMedicationMeasurementPipe,
  AlcMedicationNamePipe,
  AlcNoopPipe,
  AlcNullSymbolPipe,
  AlcOperatorPipe,
  AlcOrdinalPipe,
  AlcParenthesizePipe,
  AlcPersonNamePipe,
  AlcPluralizeMedFormPipe,
  AlcPluralizePipe,
  AlcRelativeTimePipe,
  AlcReplacePipe,
  AlcRoomPipe,
  AlcThemePipe,
  AlcTimeFormatPipe,
  AlcUtcDatePipe,
  AlcYesNoPipe
];

@NgModule({
  imports: [
    CommonModule, // TODO: Investigate if this is needed. Pipes don't import this. Do they need to?
    ...pipes
  ],
  exports: pipes
})
export class AlcPipesModule {}
