import { getPaginationData } from '$/app/utils';
import { getRecords } from '$shared/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  ResidentDietsApiActions,
  ResidentDietsGeneralActions,
  ResidentDietsWsActions
} from './resident-diets.actions';
import {
  initialState,
  ResidentDietPageQueries,
  residentDietsAdapter
} from './resident-diets.state';

export const residentDietsFeature = createFeature({
  name: 'residentDiets',
  reducer: createReducer(
    initialState,

    on(ResidentDietsApiActions.loadResidentDietsSuccess, (state, action) => {
      const queryType = action.metaData?.pageQueryType;
      const pagination = getPaginationData<ResidentDietPageQueries>(
        state,
        action.residentDiets,
        queryType
      );
      const data = getRecords(action.residentDiets);

      return residentDietsAdapter.setAll(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(ResidentDietsApiActions.getResidentDietSuccess, (state, action) => {
      const queryType = action.metaData?.pageQueryType;
      const pagination = getPaginationData<ResidentDietPageQueries>(
        state,
        action.residentDiet,
        queryType
      );
      const data = getRecords(action.residentDiet);

      return residentDietsAdapter.upsertMany(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),
    on(ResidentDietsApiActions.createResidentDietSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),
    on(ResidentDietsApiActions.updateResidentDietSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),
    on(
      ResidentDietsApiActions.createResidentDietFail,
      ResidentDietsApiActions.updateResidentDietFail,
      ResidentDietsApiActions.loadResidentDietsFail,
      ResidentDietsApiActions.getResidentDietFail,
      ResidentDietsApiActions.deleteResidentDietFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),
    on(
      ResidentDietsWsActions.residentDietsCreated,
      ResidentDietsWsActions.residentDietsPatched,
      (state, action) => {
        return residentDietsAdapter.upsertOne(action.residentDiet, state);
      }
    ),
    on(ResidentDietsWsActions.residentDietsRemoved, (state, action) => {
      return residentDietsAdapter.removeOne(action.id, state);
    }),

    on(ResidentDietsGeneralActions.addResidentDiets, (state, action) => {
      return residentDietsAdapter.upsertMany(action.residentDiets, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(ResidentDietsGeneralActions.clearResidentDiets, () => {
      return residentDietsAdapter.removeAll({ ...initialState });
    })
  )
});
