import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { IPaginationInfo } from '$/models';
import { IResidentDiet } from '$shared/services/resident-diet.schema';

export interface ResidentDietPageQueries {
  all: IPaginationInfo;
}

export const residentDietsAdapter = createEntityAdapter<IResidentDiet>();

export interface State extends EntityState<IResidentDiet> {
  pagination: ResidentDietPageQueries;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = residentDietsAdapter.getInitialState({
  pagination: {
    all: {
      total: 0,
      skip: 0
    }
  },
  loading: false,
  loaded: false,
  error: null
});
