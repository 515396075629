import { IDateRangePickerValue } from '$/app/shared/form-controls/date-range-picker-control/date-range-picker-control.component';
import { getDateTimeFormat } from '$shared/formatters/date-time-formatter';

export function dateRangeToQuery(dateRange: IDateRangePickerValue) {
  const { type, preset, year, startDate, endDate } = dateRange;

  return {
    type,
    preset,
    year,
    startDate: startDate.toFormat(getDateTimeFormat('DATE_SHORT')),
    endDate: endDate.toFormat(getDateTimeFormat('DATE_SHORT'))
  };
}
