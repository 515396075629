import { DateTimeFormatName } from '$shared/constants/datetime-formats';
import { getFormattedDateTime } from '$shared/formatters/date-time-formatter';
import { ToLuxonParam, toLuxon } from '$shared/utils';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'utcDate', standalone: true })
export class AlcUtcDatePipe implements PipeTransform {
  transform(date: ToLuxonParam, format: DateTimeFormatName = 'DATE'): any {
    if (!date) {
      return date;
    }

    const datetime = toLuxon(date).setZone('UTC');

    return getFormattedDateTime(datetime, format);
  }
}
