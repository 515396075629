import { IPaginationInfo } from '$/models';
import { IDiet } from '$shared/services/diet.schema';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface DietPageQueries {
  all: IPaginationInfo;
}

export const dietsAdapter = createEntityAdapter<IDiet>();

export interface State extends EntityState<IDiet> {
  pagination: DietPageQueries;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = dietsAdapter.getInitialState({
  pagination: {
    all: {
      total: 0,
      skip: 0
    }
  },
  loading: false,
  loaded: false,
  error: null
});
