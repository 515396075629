import { ResidentDietFormModalActions } from '$/app/pages/residents/resident-detail/resident-diet-form/resident-diet-form.actions';
import { EffectHelpersService } from '$/app/services';
import { ApiData } from '$/app/utils';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ResidentDietsApiActions } from './resident-diets.actions';
import { ResidentDietsApiService } from './resident-diets.service';
import { ResidentFacesheetPageActions } from '$/app/pages/residents/resident-detail/facesheet/facesheet.actions';
import { DietsListPageActions } from '$/app/pages/facilities/facility-detail/diets-list/diets-list.actions';

@Injectable()
export class ResidentDietsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly residentDietsService = inject(ResidentDietsApiService);

  loadResidentDiets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DietsListPageActions.loadResidentDiets),
      this.effectHelpers.apiRequest({
        description: 'loading resident diets',
        onRequest: (action) => this.residentDietsService.getAll(action.params),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'residentDiets',
            response,
            ResidentDietsApiActions.loadResidentDietsSuccess
          );
          return responseData.getActions();
        }
      })
    )
  );

  createResidentDiet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentDietFormModalActions.createResidentDiet),
      this.effectHelpers.apiRequest({
        description: 'creating resident diet',
        onRequest: (action) =>
          this.residentDietsService.create(action.residentDiet),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'residentDiets',
            response,
            ResidentDietsApiActions.createResidentDietSuccess
          );
          return responseData.getActions();
        }
      })
    );
  });

  deleteResidentDiet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentFacesheetPageActions.deleteResidentDiet),
      this.effectHelpers.apiRequest({
        description: 'deleting resident diet',
        onRequest: (action) =>
          this.residentDietsService.delete(action.id, action.params),
        onSuccess: (response) =>
          ResidentDietsApiActions.deleteResidentDietSuccess({
            id: response.id
          }),
        onError: (error) =>
          ResidentDietsApiActions.deleteResidentDietFail({ error })
      })
    );
  });
}
