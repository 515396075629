import { MixpanelService, OverlayService } from '$/app/services';
import { AlcReleaseNotesPage } from '$/app/shared/pages/release-notes/release-notes.page';
import { ApiData, SettingsValues } from '$/app/utils';
import { FACILITY_WORKSPACE_DEFAULT_ROUTE } from '$/config';
import { Logger } from '$shared/logger';
import { LoginData } from '$shared/services/user';
import { chainFlow } from '$shared/utils';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { filter, isString, map, trim, uniq } from 'lodash';
import { FacilityInitializationService } from '../pages/facility-workspace/facility-initialization-service';
import { FeathersService } from '../services/feathers.service';
import { AppActions } from '../store/app.actions';
import { FacilitySettingsGeneralActions } from '../store/facility-settings/actions';
import { UserSelectors } from '../store/user/user.selectors';
import { AuthenticationActions } from './authentication.actions';

const REDIRECT_FROM_PATHS = ['/', '/login', '/signup'];

@Injectable()
export class AuthenticationService {
  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly feathers = inject(FeathersService);
  private readonly overlay = inject(OverlayService);
  private readonly mixpanel = inject(MixpanelService);
  private readonly facilityInitializationService = inject(
    FacilityInitializationService
  );

  public async switchToFacility(options: { facilityId: string; url?: string }) {
    using loading = this.overlay.loading('Switching facilities...', {
      disableAutoHide: true
    });

    const rootRouterOutlet = document.querySelector(
      'alc-app-root > ion-app > ion-router-outlet'
    );

    rootRouterOutlet.classList.add('blur-effect');
    loading.show();

    const hideLoading = () => {
      rootRouterOutlet.classList.remove('blur-effect');
      loading.hide();
    };

    try {
      this.store.dispatch(AppActions.clearStore());
      this.store.dispatch(AuthenticationActions.authenticateFacilityChange());

      const loginData = (await this.feathers.client.authenticate({
        description: 'authenticateFacilityChange',
        facilityId: options.facilityId
      })) as LoginData;

      this.store.dispatch(
        AuthenticationActions.authenticateFacilityChangeSuccess({
          loginData,
          params: {
            onComplete: async () => {
              try {
                const userState = this.store.selectSignal(
                  UserSelectors.selectUserState
                )();

                await this.facilityInitializationService.initialize(userState);

                await this.router.navigateByUrl(
                  options.url ?? FACILITY_WORKSPACE_DEFAULT_ROUTE,
                  { onSameUrlNavigation: 'reload' }
                );
              } catch (error) {
                Logger.error('Error initializing after facility change', error);

                // There's no way to recover from this error, so we force a reload to reset the app
                window.location.href = '/';
              } finally {
                hideLoading();
              }
            }
          }
        })
      );
    } catch (error) {
      hideLoading();

      this.store.dispatch(
        AuthenticationActions.authenticateFacilityChangeFail({ error })
      );
    }
  }

  public async authenticated(loginData: LoginData) {
    this.mixpanel.identify(loginData);
    this.updateSentry(loginData);
    this.updateSettings(loginData);

    this.dispatchActions(loginData);

    await this.navigateToDashboard();

    AlcReleaseNotesPage.showIfNewVersion(this.overlay);
  }

  private updateSentry(loginData: LoginData) {
    Sentry.setUser({
      id: loginData.user.id,
      email: loginData.user.email
    });

    const { facilityUser } = loginData;

    Sentry.setTag('facilityId', facilityUser.facilityId);
    Sentry.setTag('orgId', facilityUser.orgId);
  }

  private updateSettings(loginData: LoginData) {
    SettingsValues.ACCESS_TOKEN = loginData.accessToken;
    SettingsValues.LAST_USED_EMAIL = loginData.user.email;
    SettingsValues.EMAILS_ON_THIS_DEVICE = chainFlow(
      [...SettingsValues.EMAILS_ON_THIS_DEVICE, loginData.user.email],
      (emails) => filter(emails, isString),
      (emails) => map(emails, trim),
      (emails) => filter(emails),
      uniq
    );
  }

  private dispatchActions(loginData: LoginData) {
    if (!loginData?.facilitySettings) {
      return [];
    }

    const data = new ApiData(
      'facilitySettings',
      loginData.facilitySettings,
      FacilitySettingsGeneralActions.addFacilitySettings
    );

    for (const action of data.getActions()) {
      this.store.dispatch(action);
    }
  }

  private async navigateToDashboard() {
    if (!REDIRECT_FROM_PATHS.includes(window.location.pathname)) {
      return;
    }

    await this.router.navigateByUrl(FACILITY_WORKSPACE_DEFAULT_ROUTE, {
      onSameUrlNavigation: 'reload',
      replaceUrl: true
    });
  }
}
