// @index((!^.*$)): 🔥🔥🔥🔥🔥 WE ARE RETIRING BARREL FILES - REMOVE THIS FILE AND FIX ALL RELATED IMPORTS 🔥🔥🔥🔥🔥
export * from './app-store.module';
export * as AppState from './app.state';

// Feature exports
export * from '$/app/store/allergies';
export * from '$/app/store/bowel-movement-logs';
export * from '$/app/store/care-plan-item-schedules';
export * from '$/app/store/care-plan-items';
export * from '$/app/store/care-tasks';
export * from '$/app/store/diagnoses';
export * from '$/app/store/facilities';
export * from '$/app/store/facility-settings';
export * from '$/app/store/facility-users';
export * from '$/app/store/medical-events';
export * from '$/app/store/medical-histories';
export * from '$/app/store/medical-professionals';
export * from '$/app/store/medication-inventory-items';
export * from '$/app/store/medication-mars';
export * from '$/app/store/medication-schedules/medication-schedules-store.module';
export * from '$/app/store/medication-tasks';
export * from '$/app/store/medications/medications-store.module';
export * from '$/app/store/omitted-medications';
export * from '$/app/store/pharmacies';
export * from '$/app/store/prns';
export * from '$/app/store/resident-contacts';
export * from '$/app/store/residents/residents-store.module';
export * from '$/app/store/roles';
export * from '$/app/store/rooms';
export * from '$/app/store/router';
export * from '$/app/store/shift-notes';
export * from '$/app/store/user';
