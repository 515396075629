import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DietsEffects } from './diets.effects';
import { dietsFeature } from './diets.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(dietsFeature),
    EffectsModule.forFeature([DietsEffects])
  ],
  providers: [DietsEffects]
})
export class DietsStoreModule {}
