import { Logger } from '$shared/logger';
import { GenderId } from '$shared/lookups/genders.lookup';
import { Component, Input } from '@angular/core';
import { isEmpty } from 'lodash';
import sha256 from 'tiny-hashes/sha256';
import { AlcCommonModule } from '../../alc-common.module';

@Component({
    selector: 'alc-avatar',
    imports: [AlcCommonModule],
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AlcAvatarComponent {
  @Input() type: 'employee' | 'resident' = 'employee';
  @Input() gender: GenderId;
  @Input() url: string;
  @Input() name?: string;
  @Input() size: number | string = 40;

  get src() {
    const size = Number(this.size);
    Logger.assert(isFinite(size), 'Size must be a number');

    switch (true) {
      case !isEmpty(this.url):
        return this.url;

      case this.type === 'employee' && this.gender === 'male':
      case this.type === 'employee' && this.gender === 'female':
      case this.type === 'resident' && this.gender === 'male':
      case this.type === 'resident' && this.gender === 'female':
        return `/assets/avatars/avatar-${this.gender}-${this.type}.svg`;

      default:
        return '/assets/icon/material/account_circle.svg';
    }
  }

  get hslColor() {
    if (!this.name) {
      return '';
    }
    const saturation = '100%';
    const lightness = '48%';

    const hash = sha256(this.name);

    const decimal = parseInt(hash.substring(0, 6), 16);

    const hue = decimal % 360;

    return `hsl(${hue}, ${saturation}, ${lightness})`;
  }

  get initial() {
    return this.name.charAt(0).toUpperCase();
  }
}
