import { stateConfigDictionary } from '$shared/constants/state-configuration';
import { createSelector } from '@ngrx/store';
import { FacilitiesSelectors } from '../../facilities';

const selectStateConfig = createSelector(
  FacilitiesSelectors.selectFacilityState,
  (state) => {
    return state && stateConfigDictionary[state]
      ? stateConfigDictionary[state]
      : stateConfigDictionary['US-CA'];
  }
);

const selectStateMarConfig = createSelector(
  selectStateConfig,
  (stateConfig) => {
    return stateConfig.mar;
  }
);

export const StateConfigSelectors = {
  selectStateConfig,
  selectStateMarConfig
};
