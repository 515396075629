import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IonIcon } from '@ionic/angular/standalone';

const COLOR_BY_TYPE = {
  info: 'primary',
  warning: 'warning',
  error: 'danger',
  success: 'success'
} as const;

const ICON_BY_TYPE = {
  info: 'assets/icon/material/info_outline.svg',
  warning: 'assets/icon/material/warning_outline.svg',
  error: 'assets/icon/material/report_outline.svg',
  success: 'assets/icon/material/new_releases_outline.svg'
} as const;

@Component({
    selector: 'alc-callout',
    imports: [CommonModule, IonIcon],
    templateUrl: './callout.component.html',
    styleUrls: ['./callout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AlcCalloutComponent implements OnInit {
  @Input() type: 'info' | 'warning' | 'error' | 'success' = 'info';
  @Input() color: 'primary' | 'warning' | 'danger' | 'success' | 'default';
  @Input() icon: string;

  ngOnInit() {
    this.color ??= COLOR_BY_TYPE[this.type] ?? 'default';
    this.icon ??=
      ICON_BY_TYPE[this.type] ?? 'assets/icon/material/error_outline.svg';
  }
}
