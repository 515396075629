import { range } from 'lodash';
import { DateTime } from 'luxon';

export function generateYears(
  min: number = 2019,
  max: number = DateTime.now().plus({ years: 1 }).year
): number[] {
  // range does not include the max value in the array, so in order to include
  // it, we need to add 1 to it.
  max += 1;
  return range(min, max).reverse();
}
