import { Params, Update } from '$/models';
import { IDiet } from '$shared/services/diet.schema';
import { props } from '@ngrx/store';

import { createActionGroup } from '@ngrx/store';

export const DietsListPageActions = createActionGroup({
  source: 'Diets List Page',
  events: {
    'Load Diets': (params: Params = { query: {} }) => ({ params }),
    'Delete Diet': props<{ id: string; params?: Params }>(),
    'Archive Diet': props<Update<IDiet>>(),
    'Load Resident Diets': (params: Params = { query: {} }) => ({ params })
  }
});
