import { OverlayService } from '$/app/services';
import { SharedModule } from '$/app/shared/shared.module';
import { Component, inject, Input } from '@angular/core';

@Component({
    selector: 'alc-page-image-viewer',
    imports: [SharedModule],
    templateUrl: 'image-viewer.html'
})
export class AlcImageViewerPage {
  private readonly overlay = inject(OverlayService);

  @Input() imgUrl: string;

  close() {
    this.overlay.dismissModal();
  }
}
