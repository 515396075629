import { AuthorizationService } from '$/app/services';
import { FacilitiesSelectors } from '$/app/store';
import { FeatureFlag } from '$shared/constants/feature-flags';
import { PermissionId } from '$shared/permissions';
import { MaybeArray } from '$shared/types/utility-types';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'lodash';
import { AlcCommonModule } from '../../alc-common.module';
import { AlcClickStopPropagationDirective } from '../../directives/click-stop-propagation.directive';

export interface AlcMenuButtonOption<T extends string = string> {
  id: T;
  label: string;
  icon?: string;
  iconSet?: 'material' | 'alcomy';
  permission?: MaybeArray<PermissionId>;
  featureFlag?: FeatureFlag;
  description?: string;
  disabled?: boolean;
  hide?: boolean;
}

@Component({
  selector: 'alc-menu-button',
  imports: [AlcCommonModule],
  templateUrl: './menu-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [AlcClickStopPropagationDirective]
})
export class AlcMenuButtonComponent<T extends string> {
  private readonly auth = inject(AuthorizationService);
  private readonly store = inject(Store);

  public readonly color = input<'light' | 'dark'>('dark');
  public readonly icon = input('more_vert');
  public readonly iconSet = input<'material' | 'alcomy'>('material');
  public readonly align = input<'start' | 'end' | 'middle'>('middle');
  public readonly options = input<
    AlcMenuButtonOption<T>[] | readonly AlcMenuButtonOption<T>[]
  >(undefined);
  public readonly optionSelected = output<T>();

  private readonly featureFlags = this.store.selectSignal(
    FacilitiesSelectors.selectFeatureFlags
  );

  protected readonly filteredOptions = computed(() =>
    filter(
      this.options(),
      (option) =>
        !option.hide &&
        (option.permission
          ? this.auth.hasPermission(option.permission)
          : true) &&
        (option.featureFlag ? this.featureFlags()[option.featureFlag] : true)
    ).map((option) => ({
      ...option,
      iconSet: option.iconSet ?? 'material'
    }))
  );

  protected iconCss = computed(() => {
    switch (this.align()) {
      case 'start':
        return 'ion-p-start-0';

      case 'end':
        return 'ion-p-end-0';

      default:
        return '';
    }
  });
}
