import { Params, Update } from '$/models';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { createActionGroup, props } from '@ngrx/store';

export const InventoryItemFormActions = createActionGroup({
  source: 'New Medication Inventory Form',
  events: {
    'Get Resident Medical Professionals': props<{ residentId: string }>(),
    'Load Pharmacies': (params: Params = { query: {} }) => ({ params }),
    'Get Last Medication Inventory Item': (params: Params = { query: {} }) => ({
      params
    }),
    'Create Medication Inventory Item': props<{
      medicationInventoryItem: IMedicationInventoryItem;
    }>(),
    'Update Medication Inventory Item':
      props<Update<IMedicationInventoryItem>>()
  }
});
