import { DashboardOverviewPageActions } from '$/app/pages/dashboard/dashboard-overview/dashboard-overview.actions';
import {
  IDocumentCounts,
  IIncidentReport,
  IPaginatedResponse,
  Paginated
} from '$/models';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { TMedicationInventoryItemCount } from '$shared/medications/medication-inventory-item-count.schema';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { MetricsActions } from './metrics.actions';
import { MetricsService } from './metrics.service';

@Injectable()
export class MetricsEffects {
  private readonly actions$ = inject(Actions);
  private readonly metricsService = inject(MetricsService);

  getInventoryCountDiscrepancies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardOverviewPageActions.getCountDiscrepancies),
      switchMap((action) => {
        return this.metricsService.getItemInventoryCount(action.params).pipe(
          map(
            ({ total }: IPaginatedResponse<TMedicationInventoryItemCount>) => {
              return MetricsActions.getMetricsSuccess({
                metrics: { countDiscrepancies: total }
              });
            }
          )
        );
      }),
      catchError((error) => {
        return of(MetricsActions.getMetricsFail({ error }));
      })
    );
  });

  getIncidentCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardOverviewPageActions.getIncidentReports),
      switchMap((action) => {
        return this.metricsService.getIncidentCount(action.params).pipe(
          map(({ total }: IPaginatedResponse<IIncidentReport>) => {
            return MetricsActions.getMetricsSuccess({
              metrics: { incidentReports: total }
            });
          })
        );
      }),
      catchError((error) => {
        return of(MetricsActions.getMetricsFail({ error }));
      })
    );
  });

  getMissedCareTaskCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardOverviewPageActions.getMissedCareTasks),
      switchMap((action) => {
        return this.metricsService.getMissedCareTaskCount(action.params).pipe(
          map(({ total }) => {
            return MetricsActions.getMetricsSuccess({
              metrics: { missedCareTasks: total }
            });
          })
        );
      }),
      catchError((error) => {
        return of(MetricsActions.getMetricsFail({ error }));
      })
    );
  });

  getMissedMedicationTaskCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardOverviewPageActions.getMissedMedicationTasks),
      switchMap((action) => {
        return this.metricsService.getMedicationTaskCount(action.params).pipe(
          map(({ total }) => {
            return MetricsActions.getMetricsSuccess({
              metrics: { missedMedicationTasks: total }
            });
          })
        );
      }),
      catchError((error) => {
        return of(MetricsActions.getMetricsFail({ error }));
      })
    );
  });

  getMissedPrnCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardOverviewPageActions.getMissedPrns),
      switchMap((action) => {
        return this.metricsService.getPrnCount(action.params).pipe(
          map(({ total }) => {
            return MetricsActions.getMetricsSuccess({
              metrics: { missedPrns: total }
            });
          })
        );
      }),
      catchError((error) => {
        return of(MetricsActions.getMetricsFail({ error }));
      })
    );
  });

  getMissedBowelMovementCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardOverviewPageActions.getMissedBowelMovements),
      switchMap((action) => {
        return this.metricsService.getBmLogCount(action.params).pipe(
          map(({ total }) => {
            return MetricsActions.getMetricsSuccess({
              metrics: { missedBowelMovements: total }
            });
          })
        );
      }),
      catchError((error) => {
        return of(MetricsActions.getMetricsFail({ error }));
      })
    );
  });

  getExpiredMedicationCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardOverviewPageActions.getExpiredMedications),
      switchMap((action) => {
        return this.metricsService.getMedInventoryItem(action.params).pipe(
          map(({ total }: Paginated<IMedicationInventoryItem>) => {
            return MetricsActions.getMetricsSuccess({
              metrics: { expiredMedicationInventoryItems: total }
            });
          })
        );
      }),
      catchError((error) => {
        return of(MetricsActions.getMetricsFail({ error }));
      })
    );
  });

  getExpiringMedicationCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardOverviewPageActions.getExpiringMedications),
      switchMap((action) => {
        return this.metricsService.getMedInventoryItem(action.params).pipe(
          map(({ total }: Paginated<IMedicationInventoryItem>) => {
            return MetricsActions.getMetricsSuccess({
              metrics: { expiringMedicationInventoryItems: total }
            });
          })
        );
      }),
      catchError((error) => {
        return of(MetricsActions.getMetricsFail({ error }));
      })
    );
  });

  getDocumentCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardOverviewPageActions.getDocuments),
      switchMap((action) => {
        return this.metricsService.getDocumentCount(action.params).pipe(
          map((documentCounts: IDocumentCounts) => {
            return MetricsActions.getMetricsSuccess({
              metrics: {
                completeDocuments: parseInt(documentCounts.completeCount),
                urgentDocuments: parseInt(documentCounts.urgentCount),
                upcomingDocuments: parseInt(documentCounts.upcomingCount),
                documentsTotal: parseInt(documentCounts.total)
              }
            });
          })
        );
      }),
      catchError((error) => {
        return of(MetricsActions.getMetricsFail({ error }));
      })
    );
  });

  getLowMedicationCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardOverviewPageActions.getLowMedications),
      switchMap((action) => {
        return this.metricsService.getLowMedicationsCount(action.params).pipe(
          map(({ total }) => {
            return MetricsActions.getMetricsSuccess({
              metrics: { lowMedication: total }
            });
          })
        );
      }),
      catchError((error) => {
        return of(MetricsActions.getMetricsFail({ error }));
      })
    );
  });
}
