import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { residentDietsFeature } from './resident-diets.reducer';
import { ResidentDietsEffects } from './resident-diets.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(residentDietsFeature),
    EffectsModule.forFeature([ResidentDietsEffects])
  ],
  providers: [ResidentDietsEffects]
})
export class ResidentDietsStoreModule {}
