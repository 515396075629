import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'alc-release-section',
    imports: [CommonModule],
    templateUrl: './release-section.component.html',
    styleUrls: ['./release-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlcReleaseSectionComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() date: string;
  @Input() codename: string;
}
