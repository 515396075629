import { DateFilterService } from '$/app/services';
import { IDateRangePickerValue } from '$/app/shared/form-controls/date-range-picker-control/date-range-picker-control.component';
import { InputProps } from '$/app/utils/input-props';
import { AlcomyTheme } from '$/models';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { IonButton, IonIcon } from '@ionic/angular/standalone';
import { PushPipe } from '@ngrx/component';
import { map, Observable, Subject } from 'rxjs';
import { DateRangePickerPage } from '../../pages/popovers/date-range-picker/date-range-picker.page';

@Component({
    selector: 'alc-date-range-filter-button',
    imports: [CommonModule, MatRippleModule, PushPipe, IonButton, IonIcon],
    template: `
    <ion-button
      fill="clear"
      shape="round"
      color="{{ theme }}-accent"
      (click)="openFilter($event)"
    >
      <ion-icon slot="start" src="assets/icon/material/date_range.svg" />
      <span class="text-base text-medium-dark">
        {{ activeDateRangeString$ | ngrxPush }}
      </span>
    </ion-button>
  `,
    styles: []
})
export class AlcDateRangeFilterButtonComponent implements OnInit {
  private readonly dateFilter = inject(DateFilterService);

  @Input() theme: AlcomyTheme = 'residents';
  // HACK: This component was designed to be used exclusively with the date range picker component,
  // and thus clicking it always opened that component. However, there are use cases where we'd want
  // to open another filter component when we click this button. We could create a whole new component
  // that implements the date range display functionality, but that would be a lot of duplicated code.
  // I'm hoping we can eventually refactor this component to be more generic, but for now, this is a
  // quick fix to allow us to use this component with other filter components.
  @Input() disableDefaultClick: boolean = false;
  @Input() filtersWithDateRange$: Observable<{
    dateRange: IDateRangePickerValue;
  }>;

  @Output() dateRangeChanged = new EventEmitter<IDateRangePickerValue>();

  activeDateRangeString$: Observable<string>;

  // HACK: this component desperately needs to be refactored.
  private readonly noQueryParamActiveDateRangeString = new Subject<string>();
  private noQueryParamActiveDateRange: IDateRangePickerValue;
  @Input() noQueryParams: boolean = false;
  @Input() noQueryParamInitialDateRange: IDateRangePickerValue;

  ngOnInit() {
    if (this.noQueryParams) {
      this.activeDateRangeString$ =
        this.noQueryParamActiveDateRangeString.asObservable();
      if (this.noQueryParamInitialDateRange) {
        this.noQueryParamActiveDateRange = this.noQueryParamInitialDateRange;
        setTimeout(() => {
          this.noQueryParamActiveDateRangeString.next(
            this.dateFilter.getActiveString(this.noQueryParamInitialDateRange)
          );
        });
      }
    } else if (this.filtersWithDateRange$) {
      this.activeDateRangeString$ = this.filtersWithDateRange$.pipe(
        map((filters) => {
          return this.dateFilter.getActiveString(filters.dateRange);
        })
      );
    } else {
      this.activeDateRangeString$ = this.dateFilter.activeDateRangeString$;
    }
  }

  async openFilter(ev: MouseEvent) {
    if (this.disableDefaultClick) {
      return;
    }

    const inputs: InputProps<DateRangePickerPage> = { theme: this.theme };
    if (this.noQueryParams && this.noQueryParamActiveDateRange) {
      inputs.dateRange = this.noQueryParamActiveDateRange;
    }
    const result = await this.dateFilter.openPicker(
      ev,
      inputs,
      this.noQueryParams
    );

    if (result) {
      if (this.noQueryParams) {
        this.noQueryParamActiveDateRange = result;
        this.noQueryParamActiveDateRangeString.next(
          this.dateFilter.getActiveString(result)
        );
      }
      this.dateRangeChanged.emit(result);
    }
  }
}
