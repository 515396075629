import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IDiet } from '$shared/services/diet.schema';
import { Paginated } from '$shared/types';

export const DietsApiActions = createActionGroup({
  source: 'Diets Api',
  events: {
    'Load Diets Success': props<{
      diets: Paginated<IDiet>;
      metaData: { pageQueryType: string };
    }>(),
    'Get Diet Success': props<{
      diet: IDiet;
      metaData: { pageQueryType: string };
    }>(),
    'Create Diet Success': props<{
      diet: IDiet;
    }>(),
    'Update Diet Success': props<{
      diet: IDiet;
    }>(),
    'Delete Diet Success': props<{ id: string }>(),

    'Load Diets Fail': props<{ error: Error }>(),
    'Get Diet Fail': props<{ error: Error }>(),
    'Create Diet Fail': props<{ error: Error }>(),
    'Update Diet Fail': props<{ error: Error }>(),
    'Delete Diet Fail': props<{ error: Error }>()
  }
});

export const DietsWsActions = createActionGroup({
  source: 'Diets WS',
  events: {
    'Diets Created': props<{
      diet: IDiet;
    }>(),
    'Diets Patched': props<{
      diet: IDiet;
    }>(),
    'Diets Removed': props<{ id: string }>()
  }
});

export const DietsGeneralActions = createActionGroup({
  source: 'Diets General',
  events: {
    'Clear Diets': emptyProps(),
    'Add Diets': props<{
      diets: IDiet[];
    }>()
  }
});
