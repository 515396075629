import { createFeature, createReducer, on } from '@ngrx/store';
import { MetricsActions } from './metrics.actions';
import { initialState } from './metrics.state';

export const metricsFeature = createFeature({
  name: 'metrics',
  reducer: createReducer(
    initialState,
    on(MetricsActions.getMetricsSuccess, (state, action) => {
      return {
        ...state,
        metrics: {
          ...state.metrics,
          ...action.metrics
        },
        loading: false,
        loaded: true,
        error: null
      };
    })
  )
});
