import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

type Appearance = 'fill' | 'underline' | 'plain';

@Component({
    selector: 'alc-form-divider',
    imports: [CommonModule],
    templateUrl: './form-divider.component.html',
    styleUrls: ['./form-divider.component.scss']
})
export class AlcFormDividerComponent {
  @Input() appearance: Appearance = 'fill';
}
