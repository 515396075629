import { getDateTimeFormat } from '$shared/formatters/date-time-formatter';
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'convertFrom24To12Format', standalone: true })
export class AlcTimeFormatPipe implements PipeTransform {
  transform(time: string): string {
    return DateTime.fromFormat(
      time,
      getDateTimeFormat('TIME_FROM_24')
    ).toFormat(getDateTimeFormat('TIME_12'));
  }
}
