import { AbstractApiService } from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { IResidentDiet } from '$shared/services/resident-diet.schema';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ResidentDietsWsActions } from './resident-diets.actions';

@Injectable({ providedIn: 'root' })
export class ResidentDietsApiService extends AbstractApiService<IResidentDiet> {
  constructor(feathers: FeathersService, store: Store) {
    super('resident-diets', feathers, store, {
      entityName: 'residentDiet',
      created: ResidentDietsWsActions.residentDietsCreated,
      patched: ResidentDietsWsActions.residentDietsPatched,
      removed: ResidentDietsWsActions.residentDietsRemoved
    });
  }
}
