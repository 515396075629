import { EffectHelpersService } from '$/app/services';
import {
  getAllPages,
  reducePaginatedResponses
} from '$/app/utils/pagination/rxjs-operators';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DietsApiActions } from './diets.actions';
import { DietsApiService } from './diets.service';
import { ApiData } from '$/app/utils';
import { DietsListPageActions } from '$/app/pages/facilities/facility-detail/diets-list/diets-list.actions';
import { DietsFormModalActions } from '$/app/shared/pages/forms/diets-form/diets-form.actions';
import { switchMap, tap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { IDiet } from '$shared/services/diet.schema';
import { ResidentDietFormModalActions } from '$/app/pages/residents/resident-detail/resident-diet-form/resident-diet-form.actions';

@Injectable()
export class DietsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly dietsService = inject(DietsApiService);

  loadDiets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DietsListPageActions.loadDiets,
        ResidentDietFormModalActions.loadDiets
      ),
      this.effectHelpers.apiRequest({
        description: 'loading diets',
        onRequest: (action) =>
          this.dietsService
            .getAll(action.params)
            .pipe(
              getAllPages(this.dietsService, action.params.query),
              reducePaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'diets',
            response,
            DietsApiActions.loadDietsSuccess
          );
          return responseData.getActions();
        }
      })
    )
  );

  createDiets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DietsFormModalActions.createDiet),
      this.effectHelpers.apiRequest({
        description: 'creating diet',
        onRequest: (action) => this.dietsService.create(action.diet),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'diets',
            response,
            DietsApiActions.createDietSuccess
          );
          return responseData.getActions();
        }
      })
    );
  });

  updateDiets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DietsFormModalActions.updateDiet,
        DietsListPageActions.archiveDiet
      ),
      switchMap((action) => {
        return this.dietsService.patch(action.id, action.changes).pipe(
          tap((data: IDiet) => {
            this.effectHelpers.dismissModal({ data });
          }),
          map((diet: IDiet) =>
            DietsApiActions.updateDietSuccess({
              diet
            })
          ),
          catchError((error) => {
            return of(DietsApiActions.updateDietFail({ error }));
          })
        );
      })
    );
  });

  deleteDiet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DietsListPageActions.deleteDiet),
      this.effectHelpers.apiRequest({
        description: 'deleting diet',
        onRequest: (action) =>
          this.dietsService.delete(action.id, action.params),
        onSuccess: (response) =>
          DietsApiActions.deleteDietSuccess({ id: response.id }),
        onError: (error) => DietsApiActions.deleteDietFail({ error })
      })
    );
  });
}
