import { FacilityTimeService, OverlayService } from '$/app/services';
import { AlcContactSupportComponent } from '$/app/shared/components/contact-support.component';
import { SharedModule } from '$/app/shared/shared.module';
import { AlcModal } from '$/lib/ui/alcomy-modal';
import { UserGeolocation } from '$shared/facility-settings/geolocation.types';
import { getDateTimeFormat } from '$shared/formatters/date-time-formatter';
import { IAppInfo } from '$shared/services/app-info';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { Component, Input, OnInit, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Clipboard } from '@capacitor/clipboard';

@Component({
  selector: 'alc-error-alert-page',
  imports: [SharedModule, ClipboardModule, AlcContactSupportComponent],
  templateUrl: './error-alert.modal.html',
  styles: `
    ion-content {
      --padding-start: 16px;
      --padding-end: 16px;
      --padding-top: 16px;
      --padding-bottom: 16px;
      --background: var(--ion-color-white);
    }
  `
})
export class ErrorAlertModal extends AlcModal implements OnInit {
  private readonly overlay = inject(OverlayService);
  private readonly sanitizer = inject(DomSanitizer);
  protected readonly ft = inject(FacilityTimeService);

  @Input() title: string;
  @Input() message: string;
  @Input() error: { message: string; code: number; data: any; errors: any[] };

  protected serverMessage: string | undefined;
  protected safeMessage: SafeHtml;
  protected isHTML = false;
  protected extras?: {
    requestId: string;
    userAction: string;
    userId: string;
    email: string;
    deviceId: string;
    appInfo: IAppInfo;
    deviceInfo: IAppInfo['deviceInfo'];
    platform: string;
    version: string;
    buildNumber: number;
    geolocation: UserGeolocation;
  };
  protected timestamp = this.ft
    .createDateTime()
    .toFormat(getDateTimeFormat('DEBUG_TIMESTAMP'));

  ngOnInit() {
    this.extras = this.error?.data?.extras;
    this.message ||= this.error?.message;
    if (this.message) {
      this.isHTML = this.message.includes('<');
      this.safeMessage = this.sanitizer.bypassSecurityTrustHtml(this.message);
    }

    this.serverMessage = this.error?.data?.serverMessage;

    if (this.error?.code === 403) {
      this.title = "You don't have permission";
    }

    this.title ||= 'Error';
  }

  protected async copy() {
    await Clipboard.write({
      string: [
        this.error?.data?.requestId,
        this.extras?.email,
        this.timestamp,
        `Alcomy v${this.extras?.version} (${this.extras?.buildNumber})`,
        `${this.extras?.deviceInfo?.model} (${this.extras?.appInfo?.deviceInfo?.operatingSystem} v${this.extras?.appInfo?.deviceInfo?.osVersion})`,
        this.extras?.userAction
      ]
        .filter(Boolean)
        .join('\n')
    });

    await this.overlay.showToast('success', 'Copied!');
  }
}
