import { createActionGroup, props } from '@ngrx/store';
import { Params, Update } from '$/models';
import { IDiet } from '$shared/services/diet.schema';

export const DietsFormModalActions = createActionGroup({
  source: 'Diets Form Page',
  events: {
    'Create Diet': props<{
      diet: IDiet;
      params: Params;
    }>(),
    'Update Diet': props<Update<IDiet>>()
  }
});
