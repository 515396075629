import { AlcThemePipe } from '$/app/shared/pipes/theme.pipe';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonTitle,
  IonToolbar
} from '@ionic/angular/standalone';

@Component({
    selector: 'alc-generic-modal-shell',
    imports: [
        CommonModule,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonButton,
        IonIcon,
        IonContent,
        IonFooter,
        IonLabel,
        AlcThemePipe
    ],
    templateUrl: './generic-modal-shell.component.html'
})
export class AlcGenericModalShellComponent {
  @Input({ required: true }) title: string;
  @Input() subtitle = '';

  @Input() submitText = 'Submit';
  @Input() cancelText = 'Cancel';

  @Input() hideClose = false;
  @Input() hideSubmit = false;
  @Input() hideCancel = false;
  @Input() disableSubmit = false;
  @Input() disableCancel = false;

  @Output() submitClicked = new EventEmitter<void>();
  @Output() cancelClicked = new EventEmitter<void>();

  @HostBinding('class.ion-page') ionPageClass = true;
}
