import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input
} from '@angular/core';
import { IonIcon } from '@ionic/angular/standalone';

@Component({
    selector: 'alc-release-item',
    imports: [CommonModule, IonIcon],
    templateUrl: './release-item.component.html',
    styleUrls: ['./release-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlcReleaseItemComponent {
  type = input<'improvement' | 'feature' | 'bugfix' | 'change'>();
  icon = computed(() => {
    switch (this.type()) {
      case 'improvement':
        return 'assets/icon/material/trending_up.svg';
      case 'feature':
        return 'assets/icon/material/new_releases_outline.svg';
      case 'bugfix':
        return 'assets/icon/material/bug_report.svg';
      case 'change':
        return 'assets/icon/material/change_circle.svg';
      default:
        throw new Error(
          `Invalid type for release highlight. Should be either "improvement", "change", "feature", or "bugfix". Got "${this.type()}" instead`
        );
    }
  });
}
