import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'alc-info-card-item',
    imports: [CommonModule],
    templateUrl: './info-card-item.component.html',
    styleUrls: ['./info-card-item.component.scss']
})
export class AlcInfoCardItemComponent {}
