import { OverlayService } from '$/app/services/ui/overlays';
import { AlcCommonModule } from '$/app/shared/alc-common.module';
import { AlcErrorComponent } from '$/app/shared/components/alc-error.component';
import { AlcHtmlPipe } from '$/app/shared/pipes/html.pipe';
import { AlcThemePipe } from '$/app/shared/pipes/theme.pipe';
import { AlcomyValidators } from '$/app/shared/validators';
import { validateForm } from '$/app/utils';
import { Logger } from '$shared/logger';
import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PopoverController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';

@Component({
    selector: 'alc-alert',
    imports: [AlcCommonModule, AlcThemePipe, AlcHtmlPipe, AlcErrorComponent],
    templateUrl: 'alert.modal.html'
})
export class AlertModal implements OnInit {
  private readonly store = inject(Store);
  private readonly overlay = inject(OverlayService);
  private readonly popController = inject(PopoverController);

  keywordControl = new FormControl('');

  private _type: 'info' | 'confirm' | 'confirm-keyword' = 'info';
  @Input()
  set type(value: 'info' | 'confirm' | 'confirm-keyword') {
    this._type = value || 'info';
  }
  get type(): 'info' | 'confirm' | 'confirm-keyword' {
    return this._type;
  }

  private _primaryButtonText: string = 'Ok';
  @Input()
  set primaryButtonText(value: string) {
    this._primaryButtonText = value || 'Ok';
  }
  get primaryButtonText(): string {
    return this._primaryButtonText;
  }

  private _secondaryButtonText: string = 'Cancel';
  @Input()
  set secondaryButtonText(value: string) {
    this._secondaryButtonText = value || 'Cancel';
  }
  get secondaryButtonText(): string {
    return this._secondaryButtonText;
  }

  private _title: string = 'Alert';
  @Input()
  set title(value: string) {
    this._title = value || 'Alert';
  }
  get title(): string {
    return this._title;
  }

  @Input() subtitle: string;
  @Input({ required: true }) message: string = '';
  @Input({ transform: (value: string) => value.trim() }) keyword: string;
  @Input() danger = false;

  ngOnInit() {
    if (this.type === 'confirm-keyword') {
      this.keywordControl.addValidators(AlcomyValidators.equal(this.keyword));
      this.keywordControl.updateValueAndValidity();
    }
  }

  close() {
    this.popController.dismiss(false);
  }

  async submit() {
    switch (this.type) {
      case 'info':
        this.popController.dismiss();
        break;

      case 'confirm':
        this.popController.dismiss(true);
        break;

      case 'confirm-keyword':
        try {
          validateForm(this.keywordControl);
          this.popController.dismiss(true);
        } catch (error) {
          await this.overlay.showAlert(
            'Make sure the keyword matches exactly as shown'
          );
        }
        break;
      default:
        Logger.warn('Unknown alert type', this.type);
        break;
    }
  }
}
