import { OverlayService } from '$/app/services';
import { PDFCapabilitiesService } from '$/app/services/utils/pdf-capabilities.service';
import { readFileAsDataUrl, stripDataUrlPrefix } from '$/app/utils/files';
import { environment } from '$/environments/environment';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  effect,
  inject,
  input,
  signal
} from '@angular/core';
import { FileSharer } from '@byteowls/capacitor-filesharer';
import {
  NgxExtendedPdfViewerModule,
  NgxExtendedPdfViewerService,
  pdfDefaultOptions
} from 'ngx-extended-pdf-viewer';
import { SharedModule } from '../../shared.module';

pdfDefaultOptions.disableHistory = true; // Disable recording a history of what's been viewed (pollutes localstorage)

@Component({
    selector: 'alc-pdf-viewer',
    imports: [SharedModule, NgxExtendedPdfViewerModule],
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss'],
    host: {
        class: 'block'
    }
})
export class AlcPdfViewerComponent implements OnInit, OnDestroy {
  private readonly overlay = inject(OverlayService);
  private readonly pdfViewerService = inject(NgxExtendedPdfViewerService);
  private readonly pdfCapabilitiesService = inject(PDFCapabilitiesService);

  @Input() url: string;
  @Input() filename: string;
  @Input() showDownload = false;
  @Input() height;
  loadingType = input<'inline' | 'floating'>('floating');

  @Output() ready = new EventEmitter<AlcPdfViewerComponent>();

  canPrint = this.pdfCapabilitiesService.canPrint;
  shareIcon = this.pdfCapabilitiesService.shareIcon;
  shareTooltip = this.pdfCapabilitiesService.shareTooltip;
  spinner: HTMLIonLoadingElement;

  isPdfLoading = signal(false);

  constructor() {
    // Only allow logging to the console in development mode
    globalThis['ngxConsoleFilter'] = (
      _level: string,
      _message: any
    ): boolean => {
      return environment.production;
    };

    effect(() => {
      if (this.isPdfLoading()) {
        if (this.loadingType() === 'floating') {
          this.overlay.showLoading('Loading PDF...');
        }
      } else {
        if (this.loadingType() === 'floating') {
          this.overlay.hideLoading();
        }
      }
    });
  }

  ngOnInit() {
    this.isPdfLoading.set(true);
  }

  ngOnDestroy() {
    this.isPdfLoading.set(false);
  }

  onPagesLoaded() {
    this.isPdfLoading.set(false);
    this.ready.emit(this);
  }

  onAfterPrint(_event) {}

  public async share() {
    const blob = await this.pdfViewerService.getCurrentDocumentAsBlob();

    await FileSharer.share({
      filename: this.filename,
      contentType: 'application/pdf',
      base64Data: stripDataUrlPrefix(await readFileAsDataUrl(blob))
    });
  }

  public print() {
    if (!this.canPrint) {
      return;
    }

    this.pdfViewerService.print();
  }
}
