import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { metricsFeature } from './metrics.reducer';
import { MetricsEffects } from './metrics.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(metricsFeature),
    EffectsModule.forFeature([MetricsEffects])
  ],
  providers: [MetricsEffects]
})
export class MetricsStoreModule {}
