import { AlcCommonModule } from '$/app/shared/alc-common.module';
import { AlcAvatarComponent } from '$/app/shared/components/avatar/avatar.component';
import { ResidentStatusId } from '$shared/lookups/resident-statuses.lookup';
import { IResident } from '$shared/residents';
import { Component, input } from '@angular/core';

export type ResidentStatusIdPlusUnknown = ResidentStatusId | 'unknown';

export type DisplayableResident = Pick<
  IResident,
  'firstName' | 'lastName' | 'id' | 'genderId' | 'imageFileId'
> & { statusId: ResidentStatusIdPlusUnknown };

@Component({
    selector: 'alc-resident-avatar',
    imports: [AlcCommonModule, AlcAvatarComponent],
    templateUrl: './resident-avatar.component.html'
})
export class AlcResidentAvatarComponent {
  public readonly resident = input.required<DisplayableResident | undefined>();
  public readonly size = input.required<number>();
}
